import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Modal, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import UserNavbar from '../navigation/UserNavbar';
import UserDrawer from '../navigation/UserDrawer';
import { UserState, updateSession } from '../../store/reducers/user';
import NotSupportScreenSize from '../helpers/NotSupportScreenSize';
import FormSection from '../forms/FormSection';
import { Button } from '../forms/Button';
import Holiday, { AddHolidayQueryInput } from '../../backend/api/holiday';
import User from '../../backend/api/user';
import Message from '../messages/Message';
import { BackendApiError } from '../../helpers/types';
import { AuthState, logout } from '../../store/reducers/auth';

interface ValidateForm {
  valid?: boolean;
  explaination?: string;
}

interface HolidayQuery {
  begin: string;
  end: string;
  type: string;
  status: string;
}

function Hollydays() {
  const { t } = useTranslation('hollydays');
  const userState: UserState = useSelector((state: any) => state.user);
  const authState: AuthState = useSelector((state: any) => state.auth);

  const [hollydaysType, setHollydaysType] = useState<string>(userState.hasAuthorizedRTTs === true ? 'RTT' : 'Congés');

  const [availableHollydays, setAvailableHollydays] = useState<number>(0);
  const [availableRTT, setAvailableRTT] = useState<number>(0);
  const [beginHollydaysDate, setBeginHollydaysDate] = useState<moment.Moment|null>(null);
  const [endHollydaysDate, setEndHollydaysDate] = useState<moment.Moment|null>(null);
  const [offDays, setOffDays] = useState<string>('');
  const [startDayMoment, setStartDayMoment] = useState<string>('Midi');
  const [endDayMoment, setendDayMoment] = useState<string>('Matin');
  const [startTime, setStartTime] = useState<moment.Moment|null>(null);
  const [endTime, setEndTime] = useState<moment.Moment|null>(null);
  const [comment, setComment] = useState<string>('');
  const [holidaysQueries, setHolidaysQueries] = useState<HolidayQuery[]>([]);
  const [queryId, setQueryId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState<ValidateForm>({
    valid: undefined,
    explaination: '',
  });

  const [messages, setMessages] = useState<string>('');

  const [selectedQueryInfos, setSelectedQueryInfos] = useState<any>({});

  const hollydaysTypePickerOptions = [[1, 'Congés'], [2, 'Absence courte'], [3, 'Urgence']];
  if (userState.hasAuthorizedRTTs === true) hollydaysTypePickerOptions.push([0, 'RTT']);
  console.log(userState);
  const dayMomentPickerOption = [[0, 'Matin'], [1, 'Midi'], [2, 'Soir']];

  const dispatch = useDispatch();

  const columns: any = [
    {
      title: 'Début',
      dataIndex: 'begin',
      key: 'begin',
      width: '15%',
      render: (_: any, { begin }: any) => (
        <TableItem>
          {begin}
        </TableItem>
      ),
    },
    {
      title: 'Fin',
      dataIndex: 'end',
      key: 'end',
      width: '15%',
      render: (_: any, { end }: any) => (
        <TableItem>
          {end}
        </TableItem>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '15%',
      render: (_: any, { type }: any) => (
        <TableItem>
          {type}
        </TableItem>
      ),
    },
    {
      title: 'Justificatif',
      dataIndex: 'queryId',
      key: 'queryId',
      width: '10%',
      render: (_: any, { queryId, type }: any) => (
        <TableItem>
          {type === 'Urgence' ? (
            <Button label="Ajouter" onClick={() => window.open(`https://airtable.com/embed/appvyPdwixFvlGPXr/shrYZaaLusVhlv4tR?backgroundColor=purple&prefill_Demande=${queryId}&hide_Demande=true`)} />
          ) : null}
        </TableItem>
      ),
    },
    {
      title: 'Annuler',
      dataIndex: 'cancel',
      key: 'cancel',
      width: '10%',
      render: (_: any, {
        queryId, begin, end, status,
      }: any) => (
        <TableItem>
          <Button
            label="Annuler"
            onClick={() => {
              setIsModalOpen(true);
              setSelectedQueryInfos({
                queryId, begin, end, status,
              });
            }}
          />
        </TableItem>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (_: any, { status }: any) => (
        <TableItem>
          {status}
        </TableItem>
      ),
    },
  ];

  useEffect(() => {
    updateUserInformations(authState.accessToken, dispatch, setAvailableHollydays, setAvailableRTT, setOffDays, setHolidaysQueries, navigate);
  }, [hollydaysType]);

  useEffect(() => {
    const isValid = validateForm(hollydaysType, userState.soldeConge, userState.soldeRTT, beginHollydaysDate, endHollydaysDate, userState.offDays, startDayMoment, endDayMoment, startTime, endTime, holidaysQueries);
    setIsValid(isValid);
  }, [hollydaysType, beginHollydaysDate, endHollydaysDate, startDayMoment, endDayMoment, startTime, endTime]);

  return (
    <Page>
      <NotSupportScreenSize maxWidth={850} />
      <UserDrawer />
      <UserNavbar />
      <Container>
        <ContainerHeader>
          <ComponentWithInfoSection>
            <Title>{t('hollydaysTitle')}</Title>
          </ComponentWithInfoSection>
        </ContainerHeader>
        <ContainerBody>
          <FormDiv>
            <FormSection
              type="picker"
              title="Vous souhaitez faire une demande de"
              value={hollydaysType}
              pickerOption={hollydaysTypePickerOptions}
              onChange={(e: any) => {
                setHollydaysType(e.target.options[e.target.selectedIndex].text);
              }}
              elemSize="s"
            />
            { (hollydaysType === 'RTT' || hollydaysType === 'Congés')
              ? (
                DisplayHolidayForm(
                  t,
                  hollydaysType,
                  setHollydaysType,
                  userState.id,
                  userState.hasAuthorizedRTTs,
                  availableHollydays,
                  setAvailableHollydays,
                  availableRTT,
                  setAvailableRTT,
                  offDays,
                  isValid,
                  setOffDays,
                  setBeginHollydaysDate,
                  setEndHollydaysDate,
                  beginHollydaysDate,
                  endHollydaysDate,
                  dayMomentPickerOption,
                  startDayMoment,
                  setStartDayMoment,
                  endDayMoment,
                  setendDayMoment,
                  comment,
                  setComment,
                  setMessages,
                  authState.accessToken,
                  navigate,
                  dispatch,
                )
              )
              : null}
            { (hollydaysType === 'Absence courte')
              ? (
                DisplayShortBreakForm(
                  t,
                  hollydaysType,
                  setHollydaysType,
                  isValid,
                  setBeginHollydaysDate,
                  beginHollydaysDate,
                  userState.id,
                  startTime,
                  setStartTime,
                  endTime,
                  setEndTime,
                  comment,
                  setComment,
                  setMessages,
                  authState.accessToken,
                  navigate,
                  dispatch,
                )
              )
              : null}
            { (hollydaysType === 'Urgence' && !queryId)
              ? (
                DisplayEmergencyForm(
                  t,
                  hollydaysType,
                  setHollydaysType,
                  isValid,
                  setBeginHollydaysDate,
                  setEndHollydaysDate,
                  beginHollydaysDate,
                  endHollydaysDate,
                  userState.id,
                  dayMomentPickerOption,
                  startDayMoment,
                  setStartDayMoment,
                  endDayMoment,
                  setendDayMoment,
                  setQueryId,
                  setMessages,
                  authState.accessToken,
                  navigate,
                  dispatch,
                )
              )
              : null}
            { (hollydaysType === 'Urgence' && queryId)
              ? (
                DisplayEmergencyFormPage2(queryId, setQueryId)

              ) : null}
          </FormDiv>
        </ContainerBody>
        <ContainerHeader>
          <ComponentWithInfoSection>
            <Title>{t('Historique des demandes')}</Title>
          </ComponentWithInfoSection>
        </ContainerHeader>
        <ContainerBody>
          <Table
            // rowKey="saasName"
            // rowSelection={{
            //   type: 'checkbox',
            //   ...rowSelection(setPendingSelection),
            // }}
            columns={columns}
            dataSource={holidaysQueries}
            style={{ padding: '20px', width: '100%' }}
          />
        </ContainerBody>
        <Message messages={messages} setMessages={setMessages} withLeftDrawer />
        <Modal
          title="Êtes vous sûr de vouloir annuler cette demande ?"
          visible={isModalOpen}
          onOk={async () => {
            await cancelHolidays(selectedQueryInfos.status, authState.accessToken, selectedQueryInfos.queryId, setIsModalOpen, setMessages);
            await delay(1500);
            await updateUserInformations(authState.accessToken, dispatch, setAvailableHollydays, setAvailableRTT, setOffDays, setHolidaysQueries, navigate);
          }}
          onCancel={() => setIsModalOpen(false)}
        >
          <p>{`Cela annulera votre demande du ${selectedQueryInfos.begin} au ${selectedQueryInfos.end}`}</p>
        </Modal>
      </Container>
    </Page>
  );
}

export default Hollydays;

const delay = (ms: number | undefined) => new Promise(
  // eslint-disable-next-line no-promise-executor-return
  (resolve) => setTimeout(resolve, ms),
);

function DisplayHolidayForm(
  t: any,
  hollydaysType: string,
  setHollydaysType: any,
  userId: string,
  hasAuthorizedRTTs: boolean,
  availableHollydays: number,
  setAvailableHollydays: any,
  availableRTT: number,
  setAvailableRTT: any,
  offDays: string,
  isValid: ValidateForm,
  setOffDays: any,
  setBeginHollydaysDate: any,
  setEndHollydaysDate: any,
  beginHollydaysDate: any,
  endHollydaysDate: any,
  dayMomentPickerOption: any,
  startDayMoment: string,
  setStartDayMoment: any,
  endDayMoment: string,
  setEndDayMoment: any,
  comment: string,
  setComment: any,
  setMessages: any,
  accessToken: string,
  navigate: any,
  dispatch: any,
) {
  return (
    <>
      <DoubleFormSection>
        <FormSection
          type="inputText"
          title="Solde de congés disponibles"
          value={availableHollydays}
          onChange={(e: any) => setAvailableHollydays(e.target.value)}
          inputTextPlaceholder="0"
          elemSize="s"
          disabled
        />
        { hasAuthorizedRTTs ? (
          <FormSection
            type="inputText"
            title="Solde RTT disponibles"
            value={availableRTT}
            onChange={(e: any) => setAvailableRTT(e.target.value)}
            inputTextPlaceholder="0"
            elemSize="s"
            disabled
          />
        ) : null}
      </DoubleFormSection>

      <FormSection
        type="inputText"
        title="Jours de repos"
        value={offDays}
        onChange={(e: any) => setOffDays(e.target.value)}
        inputTextPlaceholder=""
        elemSize="s"
        disabled
      />

      <DoubleFormSection>
        <FormSection
          type="inputDate"
          title="Date de départ"
          value={beginHollydaysDate}
          onChange={(value: any) => {
            setBeginHollydaysDate(value);
          }}
          elemSize="s"
        />

        <FormSection
          type="picker"
          value={startDayMoment}
          pickerOption={dayMomentPickerOption.filter((item: any) => item[1] !== 'Matin')}
          onChange={(e: any) => {
            setStartDayMoment(e.target.options[e.target.selectedIndex].text);
          }}
          elemSize="s"
        />
        <FormSection
          type="inputDate"
          title="Date de retour"
          value={endHollydaysDate}
          onChange={(value: any) => {
            setEndHollydaysDate(value);
          }}
          elemSize="s"
        />

        <FormSection
          type="picker"
          value={endDayMoment}
          pickerOption={dayMomentPickerOption.filter((item: any) => item[1] !== 'Soir')}
          onChange={(e: any) => {
            setEndDayMoment(e.target.options[e.target.selectedIndex].text);
          }}
          elemSize="s"
        />
      </DoubleFormSection>

      <FormSection
        type="inputText"
        title="Informations complémentaires"
        value={comment}
        onChange={(e: any) => setComment(e.target.value)}
        inputTextPlaceholder=""
        elemSize="s"
      />
      <FormDiv style={{ color: isValid.valid === false || isValid.explaination?.startsWith('Pas assez de jours de congés') ? 'red' : 'black', width: '100%' }}>
        {isValid.explaination}
      </FormDiv>
      <div style={{ margin: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={!isValid.valid}
          label={t('send')}
          onClick={() => submitForm(
            accessToken,
            {
              startDate: beginHollydaysDate,
              endDate: endHollydaysDate,
              holidayType: hollydaysType,
              employeeId: userId,
              startDayMoment,
              endDayMoment,
              Infos: comment,
            },
            isValid,
            setMessages,
            t,
            navigate,
            dispatch,
            setHollydaysType,
          )}
        />
      </div>
    </>
  );
}

function DisplayShortBreakForm(
  t: any,
  hollydaysType: string,
  setHollydaysType: any,
  isValid: ValidateForm,
  setBeginHollydaysDate: any,
  beginHollydaysDate: any,
  userId: string,
  startTime: any,
  setStartTime: any,
  endTime: any,
  setEndTime: any,
  comment: string,
  setComment: any,
  setMessages: any,
  accessToken: string,
  navigate: any,
  dispatch: any,
) {
  return (
    <>
      <FormSection
        type="inputDate"
        title="Date de l'absence"
        onChange={(e: any) => setBeginHollydaysDate(e)}
        elemSize="s"
      />
      <DoubleFormSection>
        <FormSection
          type="inputTime"
          title="Heure de Départ"
          onChange={(value: any) => {
            setStartTime(value);
          }}
          elemSize="s"
        />
        <FormSection
          type="inputTime"
          title="Heure de retour"
          onChange={(value: any) => {
            setEndTime(value);
          }}
          elemSize="s"
        />
      </DoubleFormSection>
      <FormSection
        type="inputText"
        title="Informations complémentaires"
        value={comment}
        onChange={(e: any) => setComment(e.target.value)}
        inputTextPlaceholder=""
        elemSize="s"
      />
      <FormDiv style={{ color: isValid.valid === false ? 'red' : 'black', width: '100%' }}>
        {isValid.explaination}
      </FormDiv>
      <div style={{ margin: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={!isValid.valid}
          label={t('send')}
          onClick={() => submitForm(
            accessToken,
            {
              startDate: beginHollydaysDate,
              holidayType: hollydaysType,
              employeeId: userId,
              startTime,
              endTime,
              Infos: comment,
            },
            isValid,
            setMessages,
            t,
            navigate,
            dispatch,
            setHollydaysType,
          )}
        />
      </div>
    </>
  );
}

function DisplayEmergencyForm(
  t: any,
  hollydaysType: string,
  setHollydaysType: any,
  isValid: ValidateForm,
  setBeginHollydaysDate: any,
  setEndHollydaysDate: any,
  beginHollydaysDate: any,
  endHollydaysDate: any,
  userId: string,
  dayMomentPickerOption: any,
  startDayMoment: string,
  setStartDayMoment: any,
  endDayMoment: string,
  setEndDayMoment: any,
  setQueryId: any,
  setMessages: any,
  accessToken: string,
  navigate: any,
  dispatch: any,
) {
  return (
    <>
      <DoubleFormSection>
        <FormSection
          type="inputDate"
          title="Date de départ"
          onChange={(e: any) => setBeginHollydaysDate(e)}
          elemSize="s"
          disablePreviousDate={false}
        />
        <FormSection
          type="picker"
          value={startDayMoment}
          pickerOption={dayMomentPickerOption.filter((item: any) => item[1] !== 'Matin')}
          onChange={(e: any) => {
            setStartDayMoment(e.target.options[e.target.selectedIndex].text);
          }}
          elemSize="s"
        />
        <FormSection
          type="inputDate"
          title="Date de retour"
          onChange={(e: any) => setEndHollydaysDate(e)}
          elemSize="s"
          disablePreviousDate={false}
        />
        <FormSection
          type="picker"
          value={endDayMoment}
          pickerOption={dayMomentPickerOption.filter((item: any) => item[1] !== 'Soir')}
          onChange={(e: any) => {
            setEndDayMoment(e.target.options[e.target.selectedIndex].text);
          }}
          elemSize="s"
        />
      </DoubleFormSection>
      <FormDiv />
      <FormDiv style={{ color: isValid.valid === false ? 'red' : 'black', width: '100%' }}>
        {isValid.explaination}
      </FormDiv>
      <div style={{ margin: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={!isValid.valid}
          label={t('send')}
          onClick={() => submitForm(
            accessToken,
            {
              startDate: beginHollydaysDate,
              endDate: endHollydaysDate,
              holidayType: hollydaysType,
              employeeId: userId,
              startDayMoment,
              endDayMoment,
            },
            isValid,
            setMessages,
            t,
            navigate,
            dispatch,
            setHollydaysType,
            setQueryId,
          )}
        />
        {' '}

      </div>
    </>
  );
}

function DisplayEmergencyFormPage2(queryId: string, setQueryId: any) {
  return (
    <>
      <div style={{ margin: '10px' }}>
        <Button onClick={() => setQueryId(null)} label="Je fournis mon justificatif plus tard dans mon historique de demande" />
      </div>
      <iframe height="700px" width="100%" title="airtableCalendar" src={`https://airtable.com/embed/appvyPdwixFvlGPXr/shrYZaaLusVhlv4tR?backgroundColor=purple&prefill_Demande=${queryId}&hide_Demande=true`} frameBorder="0" />
    </>
  );
}

function validateForm(
  hollydaysType: string,
  availableHollydays: number,
  availableRTT: number,
  beginHollydaysDate: moment.Moment|null,
  endHollydaysDate: moment.Moment|null,
  offDays: string[],
  startDayMoment: string,
  endDayMoment: string,
  startTime: any,
  endTime: any,
  holidayQueries: HolidayQuery[],
) {
  switch (hollydaysType) {
    case ('RTT'): {
      const isValid = validateCongesOrRTT(hollydaysType, beginHollydaysDate, endHollydaysDate, availableRTT, offDays, startDayMoment, endDayMoment, holidayQueries);
      return { valid: isValid.valid, explaination: isValid.explaination };
    }
    case ('Congés'): {
      const isValid = validateCongesOrRTT(hollydaysType, beginHollydaysDate, endHollydaysDate, availableHollydays, offDays, startDayMoment, endDayMoment, holidayQueries);
      return { valid: isValid.valid, explaination: isValid.explaination };
    }
    case ('Absence courte'): {
      const isValid = validateShortBreak(beginHollydaysDate, startTime?.format('HH:mm'), endTime?.format('HH:mm'));
      return { valid: isValid.valid, explaination: isValid.explaination };
    }
    case ('Urgence'): {
      const isValid = validateEmergency(beginHollydaysDate, endHollydaysDate, startDayMoment, endDayMoment, holidayQueries);
      return { valid: isValid.valid, explaination: isValid.explaination };
    }
    default: {
      // means it's not a good type
      return { valid: false, explaination: 'Type de congé mauvais' };
    }
  }
}

function validateCongesOrRTT(hollydaysType: string, beginHollydaysDate: moment.Moment|null, endHollydaysDate: moment.Moment|null, availableDays: number, offDays: string[], startDayMoment: string, endDayMoment: string, holidayQueries: HolidayQuery[]) {
  // is start date and end date are set ?
  if (!beginHollydaysDate || !endHollydaysDate) {
    return { valid: false, explaination: 'La date de début et la date de fin doivent être remplis' };
  }
  // is startDayMoment and endDayMoment are set ?
  if (!startDayMoment || !endDayMoment) {
    return { valid: false, explaination: 'Les moments de la journée doivent être précisés' };
  }

  // is start date is lower than end date ?
  const startDateWithHours = getStartAndEndDateWithHours(beginHollydaysDate, endHollydaysDate, startDayMoment, endDayMoment, null, null)[0];
  const endDateWithHours = getStartAndEndDateWithHours(beginHollydaysDate, endHollydaysDate, startDayMoment, endDayMoment, null, null)[1];
  if (startDateWithHours >= endDateWithHours) {
    return { valid: false, explaination: 'La date de début doit être inférieur à la date de fin' };
  }
  const offDaysMapping: any = {
    Lundi: 1,
    Mardi: 2,
    Mercredi: 3,
    Jeudi: 4,
    Vendredi: 5,
    Samedi: 6,
    Dimanche: 0,
  };

  // is there enought day in counters ?
  const offWeekDays = offDays?.map((item) => offDaysMapping[item]) || [];
  const holidays = getDatesWithWeekDayInRange(beginHollydaysDate, endHollydaysDate);
  const holidaysCount = countHolidays(holidays, offWeekDays, startDayMoment, endDayMoment);

  const hasEnoughtDays = holidaysCount <= availableDays;
  if (!hasEnoughtDays) {
    return {
      valid: true, explaination: `Pas assez de jours de congés, cette demande vous demande de prendre ${holidaysCount.toFixed(2)} jours et vous en avez actuellement ${availableDays.toFixed(2)}.
      Votre solde actuel n'est pas suffisant, votre demande sera tout de même étudiée par l'administration avant validation`,
    };
  }

  // check if dates overlap
  const isOverlap = checkIfHolidaysQueriesOverlaps(startDateWithHours, endDateWithHours, holidayQueries);
  if (isOverlap) {
    return { valid: false, explaination: 'Vous avez déja une demande accepté ou en attente sur ces dates' };
  }

  const returnDay = getReturnDay(offWeekDays, holidays);

  // means that endHollydaysDate is during restDay, so we set the returnMomentOfDay on Morning
  // cause return day will not be the one set in endHollydaysDate
  const returnMomentOfDay = offWeekDays.includes(endHollydaysDate.day()) ? 'Matin' : endDayMoment;
  const explaination = `
        Vous vous apprêtez a faire une demande de ${hollydaysType},
        ${holidaysCount} jours de ${hollydaysType} vous seront décomptés,
        votre départ sera le ${beginHollydaysDate.format('DD-MM-YYYY')} ${startDayMoment}
        et vous reviendrez le ${returnDay.format('DD-MM-YYYY')} ${returnMomentOfDay}
      `;
  return { valid: true, explaination };
}

function validateShortBreak(beginHollydaysDate: any, startTime: string, endTime: string) {
  // is start date and end date are set ?
  if (!beginHollydaysDate) {
    return { valid: false, explaination: 'La date doit être remplis' };
  }
  // is start time and end time are set ?
  if (!startTime || !endTime) {
    return { valid: false, explaination: 'Les heures doivent être remplis' };
  }
  // is start time lower than end time ?
  if (convertTimeInMinutes(startTime) >= convertTimeInMinutes(endTime)) {
    return { valid: false, explaination: "L'heure de début doit être plus petite que l'heure de fin" };
  }
  return { valid: true, explaination: `Vous partirez donc à ${startTime} et reviendrez à ${endTime}` };
}

function convertTimeInMinutes(time: string): number {
  const hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);
  return hours * 60 + minutes;
}

function validateEmergency(beginHollydaysDate: moment.Moment|null, endHollydaysDate: moment.Moment|null, startDayMoment: string, endDayMoment: string, holidayQueries: HolidayQuery[]) {
  // is start date and end date are set ?
  if (!beginHollydaysDate || !endHollydaysDate) {
    return { valid: false, explaination: 'La date de début et la date de fin doivent être remplis' };
  }
  // is startDayMoment and endDayMoment are set ?
  if (!startDayMoment || !endDayMoment) {
    return { valid: false, explaination: 'Les moments de la journée doivent être précisés' };
  }
  const startDateWithHours = getStartAndEndDateWithHours(beginHollydaysDate, endHollydaysDate, startDayMoment, endDayMoment, null, null)[0];
  const endDateWithHours = getStartAndEndDateWithHours(beginHollydaysDate, endHollydaysDate, startDayMoment, endDayMoment, null, null)[1];

  // is start date is lower than end date ?
  if (startDateWithHours >= endDateWithHours) {
    return { valid: false, explaination: 'La date de début doit être inférieur à la date de fin' };
  }

  // check if dates overlap
  const isOverlap = checkIfHolidaysQueriesOverlaps(startDateWithHours, endDateWithHours, holidayQueries);
  if (isOverlap) {
    return { valid: false, explaination: 'Vous avez déja une demande accepté ou en attente sur ces dates' };
  }
  return { valid: true, explaination: `Vous partirez donc en urgence le ${beginHollydaysDate.format('DD-MM-YYYY')} ${startDayMoment} et reviendrez le ${endHollydaysDate.format('DD-MM-YYYY')} ${endDayMoment}` };
}

function countHolidays(holidays: { date: moment.Moment, weekDay: number}[], offWeekDays: number[], startDayMoment: string, endDayMoment: string): number {
  if (holidays.length === 0) return 0;
  const holidaysToCount = holidays.filter((item) => !offWeekDays.includes(item.weekDay));
  // calcul to do only if start or enDate are not in rest days of employees (offWeekDays)
  let countHolidays = holidaysToCount.length;
  if (!offWeekDays.includes(holidays[0].date.day())) {
    if (startDayMoment === 'Midi') countHolidays -= 0.5;
    if (startDayMoment === 'Soir') countHolidays -= 1;
  }
  if (!offWeekDays.includes(holidays[holidays.length - 1].date.day())) {
    if (endDayMoment === 'Matin') countHolidays -= 1;
    if (endDayMoment === 'Midi') countHolidays -= 0.5;
  }
  return countHolidays;
}

function getReturnDay(offWeekDays: number[], holidays: { date: moment.Moment, weekDay: number}[]) {
  // the day the employee has to return at work so the first day not in offDays after endHoliday day
  const lastDay = holidays[holidays.length - 1].date;
  while (offWeekDays.includes(lastDay.day())) {
    lastDay.add(1, 'days');
  }
  return lastDay;
}

function getDatesWithWeekDayInRange(startDate: moment.Moment, endDate: moment.Moment): { date: moment.Moment, weekDay: number}[] {
  if (!startDate || !endDate) return [];
  const dates = [];
  const currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(endDate, 'day')) {
    dates.push({
      date: moment(currentDate),
      weekDay: currentDate.day(),
    });
    currentDate.add(1, 'days');
  }
  return dates;
}

function getStartAndEndDateWithHours(startDate: moment.Moment, endDate: moment.Moment, startDayMoment: string | null, endDayMoment: string | null, startTime: string | null, endTime: string | null) {
  const timeMomentNumberMapping: any = {
    Matin: 8,
    Midi: 12,
    Soir: 18,
  };
  let startDateWithHours = startDate;
  let endDateWithHours = endDate;
  if (startDayMoment && endDayMoment) {
    startDateWithHours = startDate.set('hour', timeMomentNumberMapping[startDayMoment]).set('minute', 0).set('second', 0);
    endDateWithHours = endDate.set('hour', timeMomentNumberMapping[endDayMoment]).set('minute', 0).set('second', 0);
  }
  // means that it's a shortBreak so start and end are the same
  if (startTime && endTime) {
    startDateWithHours = startDate.set('hour', Number(startTime.split(':')[0])).set('minute', Number(startTime.split(':')[1])).set('second', 0);
    endDateWithHours = moment(startDate).set('hour', Number(endTime.split(':')[0])).set('minute', Number(endTime.split(':')[1])).set('second', 0);
  }
  return [startDateWithHours, endDateWithHours];
}

function checkIfHolidaysQueriesOverlaps(beginHollydaysDate: moment.Moment, endHollydaysDate: moment.Moment, holidayQueries: HolidayQuery[]) {
  const holidaysQueriesNotRefused = holidayQueries.filter((item) => item.status !== 'Refusé' && item.status !== 'Annulée');
  const slots = holidaysQueriesNotRefused.map((item) => ({ start: moment(item.begin, 'DD-MM-YYYY HH:mm'), end: moment(item.end, 'DD-MM-YYYY HH:mm') }));
  slots.push({ start: beginHollydaysDate, end: endHollydaysDate });
  return checkIfSlotsOverlap(slots);
}

function checkIfSlotsOverlap(slots: { start: moment.Moment, end: moment.Moment }[]) {
  // eslint-disable-next-line no-restricted-syntax
  for (const slot1 of slots) {
    // eslint-disable-next-line no-restricted-syntax
    for (const slot2 of slots.filter((item) => item !== slot1)) {
      const start1 = slot1.start;
      const end1 = slot1.end;
      const start2 = slot2.start;
      if ((start1 >= start2 && start1 <= start2) || (end1 >= start2 && end1 <= start2)) {
        return true;
      }
    }
  }
  return false;
}

interface submitFormData {
    startDate: moment.Moment,
    endDate?: moment.Moment,
    holidayType: string,
    employeeId: string,
    startTime?: moment.Moment,
    endTime?: moment.Moment,
    startDayMoment?: string,
    endDayMoment?: string,
    Infos?: string,
}

async function submitForm(
  accessToken: string,
  formData: submitFormData,
  isValid: ValidateForm,
  setMessages: any,
  t: any,
  navigate: any,
  dispatch: any,
  setHollydaysType: any,
  setEmergencyQueryId?: any,
) {
  if (isValid.valid) {
    const data: AddHolidayQueryInput = {
      startDate: formData.startDate.format('YYYY-MM-DD'),
      endDate: formData.endDate ? formData.endDate.format('YYYY-MM-DD') : undefined,
      holidayType: formData.holidayType,
      employeeId: formData.employeeId,
      startTime: formData.startTime ? formData.startTime.format('HH:mm') : undefined,
      endTime: formData.endTime ? formData.endTime.format('HH:mm') : undefined,
      startDayMoment: formData.startDayMoment ? formData.startDayMoment : undefined,
      endDayMoment: formData.endDayMoment ? formData.endDayMoment : undefined,
      Infos: formData.Infos ? formData.Infos : undefined,
    };
    const response = await Holiday.addHolidayQuery(data, accessToken);

    if (response.status === 200) {
      if (formData.holidayType === 'Urgence') {
        const result = await response.json();
        setMessages({ texts: ['Demande soumise avec succès, veuillez fournir des justificatifs directement, ou bien après en allant voir l\'historique de vos demandes'], type: 'SUCCESS' });
        setEmergencyQueryId(result.id);
      } else {
        setMessages({ texts: ['Demande soumise avec succès, retrouvez votre demande dans votre historique de demande'], type: 'SUCCESS' });
        setHollydaysType('');
      }
    } else if (response.status === 401) {
      dispatch(logout());
      navigate('/login');
    } else {
      const result: BackendApiError = await response.json();
      setMessages({ texts: [Holiday.addHolidayQueryErrorMessages(t, result.code)], type: 'ERROR' });
    }
  } else {
    setMessages({ texts: [isValid.explaination], type: 'ERROR' });
  }
}

async function updateUserInformations(accessToken: string, dispatch: any, setAvailableHollydays: any, setAvailableRTT: any, setOffDays: any, setHolidaysQueries: any, navigate: any) {
  const response = await User.getUserInformations(accessToken);
  if (response.status === 200) {
    const result: any = await response.json();
    const soldeConge = Number(result['Solde de congés'].toFixed(2));
    const soldeRTT = result['Solde RTT'];
    const offDays = result['Jour de repos'];
    const fullName = result['Nom complet'];
    const holidaysQueries = result['Demandes de congé text'];
    setAvailableHollydays(soldeConge);
    setAvailableRTT(soldeRTT);
    setOffDays(offDays ? offDays.join(',') : '');
    setHolidaysQueries(formatHolidaysQueries(holidaysQueries));
    dispatch(updateSession({
      soldeConge, soldeRTT, offDays, fullName,
    }));
    return result;
  }
  if (response.status === 401) {
    dispatch(logout());
    navigate('/login');
  }

  return {};
}

async function cancelHolidays(status: string, accessToken: string, queryId: string, setIsModalOpen: any, setMessages: any) {
  if (status === 'En attente') {
    const response = await Holiday.cancelHolidayQuery({ Status: 'Annulée' }, queryId, accessToken);
    if (response.status === 200) {
      setIsModalOpen(false);
    }
  } else {
    setIsModalOpen(false);
    setMessages({ texts: ["Votre demande n'est pas en attente de validation, vous ne pouvez donc pas l'annuler depuis votre espace, pour l'annuler veuillez contacter l'administration"], type: 'ERROR' });
  }
  return null;
}

function formatHolidaysQueries(holidaysQueries: string) {
  return holidaysQueries.split(',').map((item: string) => {
    const elems = item.split(' | ');
    return {
      begin: elems[0], end: elems[1], type: elems[2], queryId: elems[3], status: elems[4],
    };
  }).sort((a: any, b: any) => Number(moment(b.begin, 'DD-MM-YYYY HH:mm')) - Number(moment(a.begin, 'DD-MM-YYYY HH:mm')));
}

const Page = styled.div`
    display: flex;
    height: 100%;
    font-family: 'Manrope', sans-serif;
    position: relative;
    background-color: #F4F9FF;
    margin-left: 228px;
    @media (max-width: 1212px) {
      margin-left: 0px;
    }
`;

const Container = styled.div`
  position: relative;
  margin-top: 90px;
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 95%;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ContainerBody = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormDiv = styled.div`
  width: 70%;
`;

const DoubleFormSection = styled.div`
  display: flex;
  gap: 25px;
`;

export const ComponentWithInfoSection = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 912px) {
    align-items: center;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Title = styled.div`
  line-height: 54px;
  font-family: Ubuntu, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  color: #27345E;

@media (max-width: 912px) {
  width: auto;
  font-size: 32px;
  line-height: 48px;
}

@media (max-width: 576px) {
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}
`;

export const LongTitle = styled(Title)`
@media (max-width: 1000px) {
  font-size: 24px;
  line-height: 54px;
  text-align: left;
}
@media (max-width: 912px) {
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}
`;

const TableItem = styled.div`
    font-size: 14px;
    @media (max-width: 600px) {
        font-size: 11px;
        max-width: 70px;
    }
    @media (max-width: 425px) {
        font-size: 11px;
        max-width: 40px;
      }
`;
