interface LoginBodyRequest {
    email: string,
    password: string,
}

// typer par les types de l'API
async function login(email: string, password: string) {
  const data: LoginBodyRequest = { email, password };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'mdpAirtable2K22',
    },
    body: JSON.stringify(data),
  };
  const response: any = await fetch(`${process.env.REACT_APP_API_URL}/auth/signin`, requestOptions);
  return response;
}

const mapLoginErrorsMessage: any = (t: any, errorName: string) => {
  const mapping: any = {
    WRONG_PASSWORD: t('errorWrongPassword'),
    UNKNOWN_FIREBASE_USER: t('errorUnknownUser'),
    UNKNOWN_AIRTABLE_USER: t('errorUnknownUser'),
    NOT_ALLOWED: t('errorNotAllowed'),
    CANNOT_LOG: t('errorCannotLog'),
    LOGIN_TOO_MUCH_LOGIN: t('errorTooMuchLogin'),
    SERVER_ERROR: t('errorTooMuchLogin'),
  };
  return mapping[errorName];
};

export interface LoginSuccessResponse {
  userId: string,
  soldeConge: number,
  soldeRTT: number,
  offDays: string[],
  accessTokenExpiresIn: number,
  accessToken: string,
  fullName: string,
  hasAuthorizedRTTs: boolean,
}

// typer par les types de l'API
async function resetPassword(email: string) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'mdpAirtable2K22',
    },
    body: JSON.stringify({ email }),
  };
  const response: any = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset`, requestOptions);
  return response;
}

const mapResetPasswordErrorsMessage: any = (t: any, errorName: string) => {
  const mapping: any = {
    UNKNOWN_AIRTABLE_USER: t('errorUnknownUser'),
    CANNOT_LOG: t('errorCannotLog'),
  };
  return mapping[errorName];
};

export interface ResetPasswordSuccessResponse {
  message: string,
}

export default {
  login,
  mapLoginErrorsMessage,
  resetPassword,
  mapResetPasswordErrorsMessage,
};
