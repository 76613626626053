// typer par les types de l'API
async function addHolidayQuery(data: AddHolidayQueryInput, accessTokenId: string) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessTokenId}`,
    },
    body: JSON.stringify(data),
  };
  const response: any = await fetch(`${process.env.REACT_APP_API_URL}/holidayAsk`, requestOptions);
  return response;
}

export interface AddHolidayQueryInput {
    startDate: string,
    endDate?: string,
    holidayType: string,
    employeeId: string,
    startTime?: string,
    endTime?: string,
    startDayMoment?: string,
    endDayMoment?: string,
    Infos?: string,
  }

const addHolidayQueryErrorMessages: any = (t: any, errorName: string) => {
  const mapping: any = {
    BAD_USER_TOKEN: t('errorUnauthorized'),
    BAD_ARGUMENTS: t('errorBadArguments'),
    UPDATE_USER_500: t('errorCannotUpdateUserInfo'),
    DATES_OVERLAP: t('errorDatesOverlap'),
  };
  return mapping[errorName];
};

async function cancelHolidayQuery(data: CancelHolidayQueryInput, queryId: string, accessTokenId: string) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessTokenId}`,
    },
    body: JSON.stringify(data),
  };
  const response: any = await fetch(`${process.env.REACT_APP_API_URL}/holidayAsk/${queryId}`, requestOptions);
  return response;
}

export interface CancelHolidayQueryInput {
  Status: string,
}

export default {
  addHolidayQuery,
  addHolidayQueryErrorMessages,
  cancelHolidayQuery,
};
