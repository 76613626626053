const home: any = {
  en: {
    calendarTitle: 'My calendar',
  },
  fr: {
    calendarTitle: 'Mon calendrier',
  },
};

export default home;
