import { createSlice } from '@reduxjs/toolkit';

export const user = createSlice({
  name: 'user',
  initialState: {
    id: '',
    soldeConge: '',
    soldeRTT: '',
    email: '',
    fullName: '',
    companyId: '',
    companyName: '',
    language: '',
    currency: '',
  },
  reducers: {
    initSession: (state: any, action: { payload: UserState }) => {
      state.id = action.payload.id;
      state.soldeConge = action.payload.soldeConge;
      state.soldeRTT = action.payload.soldeRTT;
      state.offDays = action.payload.offDays;
      state.fullName = action.payload.fullName;
      state.hasAuthorizedRTTs = action.payload.hasAuthorizedRTTs;
      // state.companyId = action.payload.companyId;
      // state.companyName = action.payload.companyName;
      // state.language = action.payload.language;
      // state.currency = action.payload.currency;
    },
    updateSession: (state: any, action: { payload: UpdatableUserStateInfos }) => {
      state.soldeConge = action.payload.soldeConge || state.soldeConge;
      state.soldeRTT = action.payload.soldeRTT || state.soldeRTT;
      state.offDays = action.payload.offDays || state.offDays;
      state.fullName = action.payload.fullName || state.fullName;
    },
    endSession: (state) => {
      state.id = '';
      state.email = '';
      state.fullName = '';
      state.companyId = '';
      state.companyName = '';
      state.language = '';
      state.currency = '';
    },
  },
});

export interface UserState {
  id: string,
  soldeConge: number,
  soldeRTT: number,
  offDays: string[],
  // email: string,
  fullName: string,
  // companyId: number,
  // companyName: string,
  // language: string,
  // currency: 'USD' | 'EUR',
  hasAuthorizedRTTs: boolean,
}

export interface UpdatableUserStateInfos {
  soldeConge?: number,
  soldeRTT?: number,
  offDays?: string[],
  fullName?: string,
}

// Action creators are generated for each case reducer function
export const { initSession, updateSession, endSession } = user.actions;
const userReducer = user.reducer;

export default userReducer;
