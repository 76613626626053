/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UserNavbar from '../navigation/UserNavbar';
import UserDrawer from '../navigation/UserDrawer';
import { UserState } from '../../store/reducers/user';
import { AuthState, logout } from '../../store/reducers/auth';
import NotSupportScreenSize from '../helpers/NotSupportScreenSize';
import User, { EmployeeCalendars, getEmployeeCalendarsResponse } from '../../backend/api/user';
import { BackendApiError } from '../../helpers/types';

function Home() {
  const { t } = useTranslation('home');
  const authState: AuthState = useSelector((state: any) => state.auth);
  const userState: UserState = useSelector((state: any) => state.user);
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [messages, setMessages] = useState<any>();
  const [calendarLinks, setCalendarLinks] = useState<EmployeeCalendars[]>([]);

  useEffect(() => {
    const init = async () => {
      const response = await User.getEmployeeCalendars(authState.accessToken);
      if (response.status === 200) {
        const result: EmployeeCalendars[] = await response.json();
        if (result) setCalendarLinks(result);
      } else if (response.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        const result: BackendApiError = await response.json();
        // setMessages(({ texts: [User.getEmployeeCalendarsErrorMessages(t, result.code)], type: 'ERROR' }));
      }
    };
    init();
  }, []);

  return (
    <Page>
      <NotSupportScreenSize maxWidth={850} />
      <UserDrawer />
      <UserNavbar />
      <Container>
        <ContainerHeader>
          <ComponentWithInfoSection>
            <Title>{t('calendarTitle')}</Title>
          </ComponentWithInfoSection>
        </ContainerHeader>
        {calendarLinks.length > 0 ? (
          <div style={{ width: '90%', margin: '20px' }}>
            <iframe height="700px" width="100%" title="airtableCalendar" src={calendarLinks[0]['lien vers vu']} frameBorder="0" />
          </div>
        ) : null}
        {/* <Message messages={messages} setMessages={setMessages} withLeftDrawer /> */}
      </Container>
    </Page>
  );
}

export default Home;

const Page = styled.div`
    display: flex;
    height: 100%;
    font-family: 'Manrope', sans-serif;
    position: relative;
    background-color: #F4F9FF;
    margin-left: 228px;
    @media (max-width: 1212px) {
      margin-left: 0px;
    }
`;

const Container = styled.div`
  position: relative;
  margin-top: 90px;
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 95%;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const ComponentWithInfoSection = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 912px) {
    align-items: center;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Title = styled.div`
  line-height: 54px;
  font-family: Ubuntu, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  color: #27345E;

@media (max-width: 912px) {
  width: auto;
  font-size: 32px;
  line-height: 48px;
}

@media (max-width: 576px) {
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}
`;

export const LongTitle = styled(Title)`
@media (max-width: 1000px) {
  font-size: 24px;
  line-height: 54px;
  text-align: left;
}
@media (max-width: 912px) {
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}
`;
