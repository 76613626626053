import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

// Auth
import Login from '../components/pages/Login';
// SaasManagement
// // MySaas
import Home from '../components/pages/Home';
import MyCalendars from '../components/pages/MyCalendars';
import Error from '../components/pages/Error';
import Hollydays from '../components/pages/Hollydays';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={ConnectedRoute(<Home />)} />
        <Route path="/calendars" element={ConnectedRoute(<MyCalendars />)} />
        <Route path="/hollydays" element={ConnectedRoute(<Hollydays />)} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

function ConnectedRoute(Element: any) {
  const authState = useSelector((state: any) => state.auth);
  return (authState.loggedIn && authState.accessToken) ? Element : <Navigate to="/login" state={{ autoLogout: true }} />;
}
export default Router;
