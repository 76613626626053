// typer par les types de l'API
async function getUserInformations(accessTokenId: string) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessTokenId}`,
    },
  };
  const response: any = await fetch(`${process.env.REACT_APP_API_URL}/user`, requestOptions);
  return response;
}

const mapUserInformationsErrorsMessage: any = (t: any, errorName: string) => {
  const mapping: any = {
    BAD_USER_TOKEN: t('errorUnauthorized'),
    UNKNOWN_USER: t('errorUnknownUser'),
    CANNOT_RETRIEVE_USER_INFOS: t('errorCannotRetrieveUserInfo'),
  };
  return mapping[errorName];
};

// export interface userInformationsResponse {
//   perks: any[],
// }

// typer par les types de l'API
async function getEmployeeCalendars(accessTokenId: string) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessTokenId}`,
    },
  };
  const response: any = await fetch(`${process.env.REACT_APP_API_URL}/user/calendar`, requestOptions);
  return response;
}

export interface EmployeeCalendars {
  'Nom boutique': string,
  'lien vers vu': string,
}

export interface getEmployeeCalendarsResponse {
  result: EmployeeCalendars[]
}

const getEmployeeCalendarsErrorMessages: any = (t: any, errorName: string) => {
  const mapping: any = {
    BAD_USER_TOKEN: t('errorUnauthorized'),
    UPDATE_USER_500: t('errorCannotUpdateUserInfo'),
  };
  return mapping[errorName];
};

export default {
  getUserInformations,
  mapUserInformationsErrorsMessage,
  getEmployeeCalendars,
  getEmployeeCalendarsErrorMessages,
};
