const general: any = {
  en: {
    navcalendar: 'My Calendar',
    navcalendars: 'Calendars',
    navhollydays: 'Hollydays',
    mainMenu: 'Main menu',
    logoutButton: 'Logout',
  },
  fr: {
    navcalendar: 'Mon Calendrier',
    navcalendars: 'Calendriers',
    navhollydays: 'Congés',
    mainMenu: 'Menu principal',
    logoutButton: 'Déconnexion',
  },
};

export default general;
