import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import login from './login';
import mysaas from './mysaas';
import error from './error';
import general from './general';
import home from './home';
import hollydays from './hollydays';

const resources = {
  en: {
    general: general.en,
    hollydays: hollydays.en,
    login: login.en,
    mysaas: mysaas.en,
    home: home.en,
    error: error.en,
  },
  fr: {
    general: general.fr,
    hollydays: hollydays.fr,
    login: login.fr,
    mysaas: mysaas.fr,
    home: home.fr,
    error: error.fr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    lng: 'fr',
  });

export default i18n;
