const mysaas: any = {
  en: {
    hollydaysTitle: 'Make a query',
    send: 'Send',
    errorDatesOverlap: 'You already have a holiday query on these dates',
  },
  fr: {
    hollydaysTitle: 'Faire une demande',
    send: 'Envoyer',
    errorDatesOverlap: 'Vous avez déja une demande accepté ou en attente sur ces dates',
  },
};

export default mysaas;
