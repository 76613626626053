const mysaas: any = {
  en: {
    calendarsTitle: 'My calendars',
  },
  fr: {
    calendarsTitle: 'Mes calendriers',
  },
};

export default mysaas;
